var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admin-analytics__row row-analytics"},[_c('div',{staticClass:"row-analytics__content main-color"},[_c('div',{staticClass:"row-analytics__info"},[_vm._m(0),_vm._l((_vm.features),function(item,index){return _c('div',{key:index,staticClass:"row-analytics__features"},[_c('div',{staticClass:"row-analytics__property",class:{ 'row-analytics__role': item.color },style:([
            item.color
              ? {
                  background: item.color,
                  'font-weight': 400,
                  'font-size': '12px',
                  padding: '2px 4px',
                }
              : undefined,
            item.weight ? { 'font-weight': item.weight } : undefined,
          ])},[_c('p',[_vm._v(_vm._s(item.property))])]),_c('div',{staticClass:"row-analytics__value"},[_c('p',[_vm._v(_vm._s(item.value))])])])})],2),_c('div',{staticClass:"row-analytics__graph"},[_c('line-chart',{attrs:{"styles":{ height: '150px' }}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row-analytics__title"},[_c('p',[_vm._v("Всего")])])
}]

export { render, staticRenderFns }